import {Component, Inject, TemplateRef, ViewChild} from '@angular/core';
import { DecoupledModalBridgeService } from '../../../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import {
  DtoFrontendModalType,
  IShowHeaderComponent
} from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { TranslatorService } from '../../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-view-column-configuration-material',
  templateUrl: './view-column-configuration-material.component.html',
  styleUrls: ['./view-column-configuration-material.component.scss']
})
export class ViewColumnConfigurationMaterialComponent {

  @ViewChild('columnsModal', {static: true}) columnsModal: TemplateRef<any>;

  /**
   * Configuration modal reference.
   */
  modalRef: ModalReference<unknown>;

  public hideColumn: boolean = false;

  constructor(
      @Inject('extraParamsViewHeader') public configData: IShowHeaderComponent,
      private dmbs: DecoupledModalBridgeService,
      private translatorService: TranslatorService
  ) {
    this.hideColumn = configData.HideColumns;
  }


  /**
   * Shows the column configuration modal.
   */
  showColumnConfiguration(): void {
    this.modalRef = this.dmbs.showTemplate(this.columnsModal, {
      Title: this.translatorService.get('Añadir / quitar columnas'),
      ModalType: DtoFrontendModalType.Modal,
    });
  }
}

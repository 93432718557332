import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnDestroy,
  OnInit,
  SkipSelf
} from '@angular/core';

import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementWrapper } from '../../formelementwrapper.class';
import { FrontendFormElementInput } from '../../formelementinput.class';
import Vimeo from '@vimeo/player';
import { FormElementVideoType } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from '../../../../utils/typescript.utils';
import Hls from 'hls.js';

/**
 * Este componente no necesita el Control Value Accessor por que es únicamente de salida
 */
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html'
})
export class VideoComponent extends FrontendFormElementWrapper implements AfterViewInit, OnDestroy, OnInit {

  FormElementVideoType = FormElementVideoType;

  private hls: Hls | undefined;
  protected url: string;

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }

  /**
   * FormElementSimpleMessage class constructor.
   */
  constructor(
      protected formManager: FormManagerService,
      protected cdRef: ChangeDetectorRef,
      @SkipSelf()
      protected cdRefParent: ChangeDetectorRef,
      protected elementRef: ElementRef) {
    super(formManager, cdRef, cdRefParent);
  }

  ngOnInit(): void {
    if (!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
      const tag: any = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
    }
  }

  ngAfterViewInit(): void {
    const optionsVimeo: any = {
      id: this.config.VideoId,
      autoplay: this.config.Autoplay,
      controls: this.config.Controls,
      height: this.config.Height,
      width: this.config.Width,
      loop: this.config.Loop,
      muted: this.config.Muted
    };

    if (!isNullOrUndefined(this.elementRef.nativeElement.querySelector('.vimeo-player'))) {
      const player: any = new Vimeo(this.elementRef.nativeElement.querySelector('.vimeo-player'), optionsVimeo);
    }

    this.url = this.config.defaultValue;

    if (!isNullOrUndefined(this.url) && !isNullOrUndefined(this.elementRef.nativeElement.querySelector('#' + this.uniqueComponentId('inputVideoPlayer-')))) {
      const video: any = this.elementRef.nativeElement.querySelector('#' + this.uniqueComponentId('inputVideoPlayer-'));

      if (Hls.isSupported() && this.url.endsWith('.m3u8')) {
        this.hls = new Hls();
        this.hls.loadSource(this.url);
        this.hls.attachMedia(video);
      } else {
        if (!video.canPlayType('application/vnd.apple.mpegurl')) {
          console.debug('Video component not support application/vnd.apple.mpegurl')
        }
        video.src = this.url;
      }
    }
  }

  ngOnDestroy(): void {
    this.hls?.destroy();
  }
}

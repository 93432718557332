import {Component, Inject} from '@angular/core';
import { ViewFilterTriggerComponent } from '../../../view-headers-shared/filters/components/view-filter-trigger.component';
import {ListComponent2Service} from '../../../../list.service';
import {DateTimeService} from '../../../../../../core/date-time/date-time.service';
import {DecoupledModalBridgeService} from '../../../../../decoupled-modal/decoupled-modal-bridge.service';
import {TranslatorService} from '../../../../../../core/translator/services/rest-translator.service';
import {IShowHeaderComponent} from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-view-filter-material',
  templateUrl: './view-filter-material.component.html',
  styleUrls: ['./view-filter-material.component.scss']
})
export class ViewFilterMaterialComponent extends ViewFilterTriggerComponent {

  public hideFilter: boolean = false;

  constructor(
      listService: ListComponent2Service,
      dateTimeService: DateTimeService,
      dmbs: DecoupledModalBridgeService,
      translatorService: TranslatorService,
      @Inject('extraParamsViewHeader') public configData: IShowHeaderComponent
  ) {
    super(listService, dateTimeService, dmbs, translatorService);

    this.hideFilter = configData.HideFilter;
  }
}
